import * as React from 'react'
import { store } from 'store/index'
import { deserialize } from '../../api/utils/deprecated_deserialize'
import { reactionsSchema } from '../../contracts/reaction'
import { ReactionBar } from './reaction-bar'

export type RailsReactionBarProps = {
  reactableId: string
  reactableType: string
  reactions: unknown
}

export const RailsReactionBar: React.VFC<RailsReactionBarProps> = (props) => {
  const { reactableId, reactableType, reactions } = props

  const { data } = deserialize(reactions)
  const parsedData = reactionsSchema.parse(data)

  const storeReactions = parsedData.reduce(
    (hsh, r) => ({ ...hsh, [r.id]: r }),
    {}
  )
  store.reactions.load(storeReactions)

  return <ReactionBar reactableId={reactableId} reactableType={reactableType} />
}
