import { store } from 'store/index'
import { tabIdType } from 'pages/wins-page/wins-page'
import { sort } from 'store/modules/activities/filters'

export class WinActivityFeedVm {
  constructor(
    private setHasNextPage: (hasNextPage: boolean) => void,
    private pageNumber: number,
    private setPageNumber: (pageNumber: number) => void,

    private tabId: tabIdType,
    private userId: string[],
    private teamUserIds?: string[]
  ) {}

  private perPage = 25

  get filteredActivities() {
    switch (this.tabId) {
      case 'received':
        return store.activities.filteredActivities({
          recordType: 'Win',
          userId: this.userId,
        })
      case 'given':
        return sort(
          store.activities.all.filter(
            (activity) =>
              activity.recordType == 'Win' &&
              this.userId.includes(activity.record.reporter.id)
          )
        )
      case 'org':
        return sort(
          store.activities.all.filter(
            (activity) => activity.recordType == 'Win'
          )
        )
      case 'team':
        return sort(
          store.activities.all.filter(
            (activity) =>
              activity.recordType == 'Win' &&
              activity.record.visibility == 'org' &&
              this.teamUserIds &&
              this.teamUserIds.includes(activity.record.reporter.id)
          )
        )
      default:
        return store.activities.filteredActivities({})
    }
  }

  async fetchActivities(pageNumber = this.pageNumber) {
    if (this.tabId === 'received') {
      const { meta } = await store.activities.fetchAllWithAllIncludes(
        this.perPage,
        { record_type: ['Win'], user_id: this.userId },
        pageNumber
      )
      const hasNextPage = meta?.pages && meta.pages > pageNumber
      this.setHasNextPage(!!hasNextPage)
    } else if (this.tabId === 'given') {
      const { meta } = await store.activities.fetchAllWithAllIncludes(
        this.perPage,
        { record_type: ['Win'], author_id: this.userId },
        pageNumber
      )
      const hasNextPage = meta?.pages && meta.pages > pageNumber
      this.setHasNextPage(!!hasNextPage)
    } else if (this.tabId === 'org') {
      const { meta } = await store.activities.fetchAllWithAllIncludes(
        this.perPage,
        { record_type: ['Win'], user_id: 'all' },
        pageNumber
      )
      const hasNextPage = meta?.pages && meta.pages > pageNumber
      this.setHasNextPage(!!hasNextPage)
    } else if (this.tabId === 'team') {
      const { meta } = await store.activities.fetchAllWithAllIncludes(
        this.perPage,
        { record_type: ['Win'], user_id: this.teamUserIds },
        pageNumber
      )
      const hasNextPage = meta?.pages && meta.pages > pageNumber
      this.setHasNextPage(!!hasNextPage)
    }
  }

  async loadNextPage() {
    const nextPage = this.pageNumber + 1
    this.setPageNumber(nextPage)
    await this.fetchActivities(nextPage)
  }
}
